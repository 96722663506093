<template>
    <div>
        <section class="component-box">
            <div class="flex justify-between space-x-3 my-4">
                <div>
                    <p>
                        Tổng số giao dịch:
                        {{
                            transaction_display_list.length | f__format_currency_vnd
                        }}
                    </p>
                    <p>
                        Tổng số tiền nạp:
                        <b>
                            {{
                                transaction_display_list.reduce(
                                    (t, i) =>
                                        t +
                                        (i.cash_amount || 0) +
                                        (i.fee_amount || 0),
                                    0
                                ) | f__format_currency_vnd
                            }}
                        </b>
                    </p>
                </div>
                <div class="flex justify-end">
                    <el-date-picker
                        v-model="ranger_date"
                        class="w-[30%]"
                        size="small"
                        type="daterange"
                        unlink-panels
                        :start-placeholder="$t('date_picker.placeholder.start')"
                        :end-placeholder="$t('date_picker.placeholder.end')"
                        :format="$t('common.format_value_date_picker')"
                        value-format="yyyy-MM-dd"
                        @change="getTransactionList"
                    >
                    </el-date-picker>

                    <el-button
                        type="default"
                        size="small"
                        icon="el-icon-refresh"
                        class="ml-2"
                        @click="getTransactionList"
                    ></el-button>
                </div>
            </div>

            <!--            <pre>-->
            <!--                {{ transaction_display_list }}-->
            <!--            </pre>-->

            <el-table v-loading="m__loading" :data="transaction_display_list">
                <el-table-column :label="$t('common.ad_account')">
                    <template slot-scope="scope">
                        <div>ID: {{ scope.row.advertiser_id }}</div>
                        <div>
                            {{
                                scope.row.extra_data?.request_params
                                    ?.advertiser_name
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.admin.cash_amount')">
                    <template slot-scope="scope">
                        {{ scope.row.cash_amount | f__format_currency_vnd }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.admin.fee_amount')">
                    <template slot-scope="scope">
                        {{ scope.row.fee_amount | f__format_currency_vnd }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.admin.total_cash_amount')">
                    <template slot-scope="scope">
                        {{
                            (scope.row.cash_amount + scope.row.fee_amount)
                                | f__format_currency_vnd
                        }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('common.balance_type')">
                    <template slot-scope="scope">
                        {{ $t('common.transfers') }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('common.created_at')">
                    <template slot-scope="scope">
                        {{
                            scope.row.created_at
                                | f__format_moment($t('common.format_fulldate'))
                        }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('common.operator')">
                    <template slot-scope="scope">
                        {{
                            scope.row.created_by?.display_name ||
                            scope.row.extra_data?.api_response?.data?.store_id
                        }}
                    </template>
                </el-table-column>
            </el-table>
        </section>
    </div>
</template>

<script>
import moment from 'moment'
import { drop as _drop, take as _take } from 'lodash'
import { getAdAccountTransactionHistoryByAdmin } from '@/services/atosa-tiktok-ads/payment'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    data() {
        return {
            loading: {
                get_data: false
            },
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 90),
                new Date()
            ],
            transaction_list: []
        }
    },

    computed: {
        transaction_display_list() {
            const limit = this.m__page_info.page_size
            const skip = limit * (this.m__page_info.page - 1)

            return _take(_drop(this.transaction_list, skip), limit)
        }
    },

    beforeMount() {
        this.getTransactionList()
    },

    methods: {
        async getTransactionList() {
            this.m__loading = true

            try {
                const start_time = moment(this.ranger_date[0]).format(
                    'YYYY-MM-DD'
                )
                const end_time = moment(this.ranger_date[1]).format(
                    'YYYY-MM-DD'
                )
                const response = await getAdAccountTransactionHistoryByAdmin(
                    start_time,
                    end_time
                )
                this.transaction_list = response.data
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
