import request from '@/services/atosa-tiktok-ads/_config'

const path = `payment`

export function addFund(tiktok_account_id, data) {
    return request({
        url: `${path}/add_fund/${tiktok_account_id}`,
        method: 'post',
        data: data
    })
}

export function addPaymentManual(data) {
    return request({
        url: `${path}/add_payment_manual/atame`,
        method: 'post',
        data: data
    })
}

export function addPaymentManualTiktok(data) {
    return request({
        url: `${path}/add_payment_manual/tiktok`,
        method: 'post',
        data: data
    })
}

export function getManualPayments(key, user_key, page) {
    return request({
        url: `${path}/payment_manual?key=${key}&user_key=${user_key}&page=${page}&page_size=10`,
        method: 'get'
    })
}

export function getAdAccountTransactionHistory(start_time, end_time) {
    return request({
        url: `${path}/ad_account_trans_history`,
        method: 'get',
        params: { start_time, end_time }
    })
}

export function getAdAccountTransactionHistoryByAdmin(start_time, end_time) {
    return request({
        url: `${path}/ad_account_trans_history/admin`,
        method: 'get',
        params: { start_time, end_time }
    })
}

export function getUserTransactionHistory(start_time, end_time) {
    return request({
        url: `${path}/user_trans_history`,
        method: 'get',
        params: { start_time, end_time }
    })
}

export function calculateRechargeFee(data) {
    return request({
        url: `${path}/calculate_recharge_fee`,
        method: 'post',
        data: data
    })
}

export function getHistoryPayment(params) {
    return request({
        url: `${path}/report/history`,
        method: 'get',
        params
    })
}

export function getBillingAddress(params) {
    return request({
        url: `${path}/billing_address`,
        method: 'get',
        params
    })
}

export function setBillingAddress(params) {
    return request({
        url: `${path}/billing_address`,
        method: 'get',
        params
    })
}
